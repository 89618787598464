import { DropdownMenuButton } from "@jutro/components";
import { DropdownMenuLink } from '@jutro/router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CONSTANTS } from "wmic-pe-portals-utils-js";
import { TranslatorContext } from '@jutro/locale';
import { useContext } from 'react';
import messages from './WMICPUPAddHouseholdMemberDropdownMenu.messages.js';

const canContactBeAddedToHouseholdMembers = (contact, householdMembers) => {
    return contact.subtype.toLowerCase() === CONSTANTS.Person.toLowerCase()
        && (_.isEmpty(householdMembers)
            || householdMembers.every(householdMember => {
                return householdMember?.person?.publicID !== contact?.contactPublicID;
            }));
};

function WMICPUPAddHouseholdMemberDropdownMenu(props) {
    const {
        additionalNamedInsureds,
        isEditing,
        onClick,
        householdMembers
    } = props;

    const translator = useContext(TranslatorContext);

    return (
        <DropdownMenuButton
            id="addNewHouseholdMemberButton"
            buttonText={translator(messages.addHouseholdMember)}
            icon="mi-add"
            disabled={isEditing}>
            {additionalNamedInsureds?.map((ani, index) => (
                canContactBeAddedToHouseholdMembers(ani, householdMembers) && <DropdownMenuLink
                    onClick={() => onClick(
                        householdMembers.length,
                        _.get(additionalNamedInsureds, index))}>
                    {ani.displayName}
                </DropdownMenuLink>
            ))}
            <DropdownMenuLink onClick={() => onClick(
                householdMembers?.length
            )}>{translator(messages.addHouseholdMember)}</DropdownMenuLink>
        </DropdownMenuButton>
    );
}

WMICPUPAddHouseholdMemberDropdownMenu.propTypes = {
    additionalNamedInsureds: PropTypes.shape([]).isRequired,
    isEditing: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    householdMembers: PropTypes.shape([]).isRequired,
};

export default WMICPUPAddHouseholdMemberDropdownMenu;
