/* eslint-disable max-len */
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import WMICSplitIoFeatureFlags from './WMICSplitIoFeatureFlags'
import messages from './WMICFeatureFlagUtil.messages';
import { FEATURE_FLAG_DEFAULT_VALUES, FEATURE_FLAG_NAMES } from '../StringConstants';

dayjs.extend(customParseFormat);

const OUTAGE_TYPES = {
    UNAVAILABLE: 'unavailable',
    OUTAGE: 'outage',
    PLANNED: 'plannedOutage',
    AVAILABLE: 'isAvailable'
};

const REASONS = {
    REGION: 'region',
    OUTAGE: 'outage'
};

// ************** QB Functionality ****************
const queryAvailabilityQB = (ldFlags, params) => {
    const response = {
        isAvailable: true,
        reason: ''
    };
    const key = params ? params.featureName : '';
    if (params && key) {
        const variationResult = ldFlags[key];
        if (variationResult) {
            response.isAvailable = variationResult.isAvailable !== undefined
                && (variationResult.isAvailable === true || (typeof variationResult.isAvailable === 'string' && variationResult.isAvailable.toLowerCase() === 'true'));
            if (response.isAvailable) {
                if (params.regionCode) {
                    if (Array.isArray(variationResult.availableRegionCodes) && variationResult.availableRegionCodes.length > 0) {
                        if (variationResult.availableRegionCodes.indexOf(params.regionCode) === -1) {
                            response.isAvailable = false;
                            response.reason = REASONS.REGION;
                        }
                    }
                }

                if (response.isAvailable && variationResult.outageStartDate && variationResult.outageEndDate) {
                    const { serverInfo } = params;
                    const serverTimeOffset = serverInfo ? serverInfo.timezoneOffset : '-0500';
                    // When there is an outage range, check if the effective date is in the range
                    const effectiveDateMoment = params.effectiveDate ? dayjs(params.effectiveDate) : dayjs();
                    const outageStartDateMoment = dayjs(variationResult.outageStartDate + serverTimeOffset, 'MM/DD/YYYYThh:mm:ssZZ'); // 09/25/2021T08:00:00-0500
                    const outageEndDateMoment = dayjs(variationResult.outageEndDate + serverTimeOffset, 'MM/DD/YYYYThh:mm:ssZZ');

                    response.isAvailable = effectiveDateMoment.isBefore(outageStartDateMoment, 'second')
                        || (effectiveDateMoment.isAfter(outageEndDateMoment, 'second') || effectiveDateMoment.isSame(outageEndDateMoment, 'second'));

                    if (response.isAvailable === false) {
                        response.reason = REASONS.OUTAGE;
                    }
                }
                //Map effectiveValue in config in FF flag.
                if (response.isAvailable && variationResult.effectiveDate) {
                    response.effectiveDate = variationResult.effectiveDate
                }
            }
        } else {
            response.isAvailable = false;
        }
    } else {
        response.isAvailable = false;
    }

    return response;
};

// ************** AMP Functionality ****************
const NOTICE_TYPE = {
    NONE: 'none',
    AMP: 'amp',
    CLAIMS: 'claims',
    CARD: 'card',
    BANK: 'bank',
    MANAGEBANKACCOUNTS: 'manageBankAccounts',
    PAYMENTOPTIONS: 'paymentoptions',
    CUSTOMEREVENTS: 'customerevents',
    POLICYCHANGE: 'policychange',
    FUTUREDATEDOTWPAYMENTS: 'futuredatedotwpayments',
    ASSISTEDPOLICYCHANGE: 'enableWebPolicyChangeForms',
    CLAIMSFNOL: 'enableClaimsFNOL',
    EMQ: 'enableEMQ',
    ONEINCPAYMENTS: 'oneincpayment',
    PAYMENTS: 'payments'
};

const FEATURE_NAMES = {
    loggingCsrf: 'logging-csrf'
};

const AMPOutageResult = { type: NOTICE_TYPE.AMP };
const claimOutageResult = { type: NOTICE_TYPE.CLAIMS };
const cardOutageResult = { type: NOTICE_TYPE.CARD };
const bankOutageResult = { type: NOTICE_TYPE.BANK };
const paymentOutageResult = { type: NOTICE_TYPE.PAYMENTOPTIONS };
const policyChangeOutageResult = { type: NOTICE_TYPE.POLICYCHANGE };
const manageBankAccountsOutageResult = { type: NOTICE_TYPE.MANAGEBANKACCOUNTS };
const paymentsOutageResult = { type: NOTICE_TYPE.PAYMENTS };

const getSourceTimeZoneOffset = (dateString) => {
    const timezoneAbbr = { DaylightSavings: '.000-0500', StandardTime: '.000-0600' };
    const date = new Date(`${dateString}Z`);

    return date.toString().includes('Daylight') ? timezoneAbbr.DaylightSavings : timezoneAbbr.StandardTime;
};

/* UtilityService's date format is not valid in JavaScript, so need to convert to Js format */
const formatToJsDate = (outageDate) => {
    const splittedDateString = outageDate.split('T');
    const splittedDate = splittedDateString[0].split('/');
    let newDate = `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}T${splittedDateString[1]}`;
    const timezoneOffset = getSourceTimeZoneOffset(newDate);
    newDate += timezoneOffset;

    return new Date(newDate);
};

/* Format the date to be displayed for the planned claimCenter outage message */
const formatDate = (outageDate) => {
    let stringDate = '';
    let hours = outageDate.getHours();
    const mins = outageDate.getMinutes().toString().padStart(2, '0');
    let dayOfWeek = outageDate.getDay();
    let month = outageDate.getMonth();
    const meridiem = hours >= 12 && hours <= 23 ? 'pm' : 'am';
    const day = outageDate.getDate();
    const timeZoneShort = outageDate.toLocaleDateString('en-US', { timeZoneName: 'short' }).slice(-3);
    hours %= 12;
    if (hours === 0) {
        hours = 12;
    }

    dayOfWeek = moment.weekdays(dayOfWeek);
    month = moment.months(month);

    stringDate = `${hours}:${mins} ${meridiem} (${timeZoneShort}) ${dayOfWeek}, ${month} ${day}`;
    return stringDate;
};

function setStartAndEndDateForFeature(feature, startDate, endDate) {
    _.set(feature, 'outageStart', formatDate(formatToJsDate(startDate)));
    _.set(feature, 'outageEnd', formatDate(formatToJsDate(endDate)));
}

function hasNotificationDate(outage) {
    return outage.outageStartDate && outage.outageEndDate;
}

function hasAnUpcomingOutage(outage, currentDate) {
    return hasNotificationDate(outage) && formatToJsDate(outage.outageStartDate) >= currentDate;
}

function hasOutageDateRange(availabilityResponse) {
    return availabilityResponse.outageStartDate && availabilityResponse.outageEndDate;
}

function isSameAvailability(notice1, notice2) {
    return notice1.availability === notice2.availability;
}

function isPaymentOptionsFeature(feature) {
    return feature.type === NOTICE_TYPE.PAYMENTOPTIONS;
}

function isClaimsFeature(feature) {
    return feature.type === NOTICE_TYPE.CLAIMS;
}

function isCreditCardFeature(feature) {
    return feature.type === NOTICE_TYPE.CARD;
}

function isBankPaymentFeature(feature) {
    return feature.type === NOTICE_TYPE.BANK;
}

function isPaymentsFeature(feature) {
    return feature.type === NOTICE_TYPE.PAYMENTS;
}

function isAMPFeature(feature) {
    return feature.type === NOTICE_TYPE.AMP;
}

function isUnavailable(notice) {
    return notice.availability === OUTAGE_TYPES.UNAVAILABLE;
}

function isBothUnavailable(notice1, notice2) {
    return isUnavailable(notice1) && isUnavailable(notice2);
}

function isOutage(notice) {
    return notice.availability === OUTAGE_TYPES.OUTAGE;
}

function isBothOutage(notice1, notice2) {
    return isOutage(notice1) && isOutage(notice2);
}

function setUpUpcomingOutageNotfication(feature, translator) {
    const notice = {
        type: feature.type,
        availability: OUTAGE_TYPES.PLANNED,
        origOutageStartFormat: feature.origOutageStartFormat,
        origOutageEndFormat: feature.origOutageEndFormat
    };

    if (isAMPFeature(feature)) {
        notice.header = translator(messages.schedOutageOnlineAccountAccess);
        notice.body = translator(messages.accountAccessUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isPaymentOptionsFeature(feature)) {
        notice.header = translator(messages.outagePaymentOptions);
        notice.body = translator(messages.paymentOptionsUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isClaimsFeature(feature)) {
        notice.header = translator(messages.outageClaim);
        notice.body = translator(messages.claimUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isCreditCardFeature(feature)) {
        notice.header = translator(messages.scheduleOutageCreditCardPayment);
        notice.body = translator(messages.creditCardUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isBankPaymentFeature(feature)) {
        notice.header = translator(messages.scheduleOutageBankAccountPayment);
        notice.body = translator(messages.bankAccountUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isPaymentsFeature(feature)) {
        notice.header = translator(messages.scheduledOutageForPaymentSystem);
        notice.body = translator(messages.paymentSystemUpcomingOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    }

    return notice;
}

function setUpUnavailableNotification(feature, translator) {
    const notice = {
        type: feature.type,
        availability: OUTAGE_TYPES.UNAVAILABLE,
    };

    if (isPaymentOptionsFeature(feature)) {
        notice.header = translator(messages.paymentOptionsOutage);
        notice.body = translator(messages.paymentOptionsUnplannedOutage);
    } else if (isClaimsFeature(feature)) {
        notice.header = translator(messages.claimsUnavailable);
        notice.body = translator(messages.claimsUnplannedOutage);
    } else if (isCreditCardFeature(feature)) {
        notice.header = translator(messages.creditCardPaymentsUnavailable);
        notice.body = translator(messages.creditCardPaymentsUnplannedOutage);
    } else if (isBankPaymentFeature(feature)) {
        notice.header = translator(messages.bankAccountPaymentsUnavailable);
        notice.body = translator(messages.bankAccountPaymentUnplannedOutage);
    } else if (isPaymentsFeature(feature)) {
        notice.header = translator(messages.paymentSystemIsCurrentlyUnavailable);
        notice.body = translator(messages.paymentSystemUnplannedOutage);
    }

    return notice;
}

function setUpPlannedOutageNotification(feature, translator) {
    const notice = {
        type: feature.type,
        availability: OUTAGE_TYPES.OUTAGE,
        origOutageStartFormat: feature.origOutageStartFormat,
        origOutageEndFormat: feature.origOutageEndFormat
    };

    if (isPaymentOptionsFeature(feature)) {
        notice.header = translator(messages.outagePaymentOptions);
        notice.body = translator(messages.paymentOptionsPlannedOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isClaimsFeature(feature)) {
        notice.header = translator(messages.outageClaim);
        notice.body = translator(messages.claimPlannedOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isCreditCardFeature(feature)) {
        notice.header = translator(messages.scheduleOutageCreditCardPayment);
        notice.body = translator(messages.creditCardPlannedOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    } else if (isBankPaymentFeature(feature)) {
        notice.header = translator(messages.scheduleOutageBankAccountPayment);
        notice.body = translator(messages.bankAccountPaymantsPlannedOutage, { outageStart: feature.outageStart, outageEnd: feature.outageEnd });
    }

    return notice;
}

function setOutageResult(outage, feature, currentDate, translator) {
    let notice;
    if (hasNotificationDate(outage)) {
        _.set(feature, 'origOutageStartFormat', outage.outageStartDate);
        _.set(feature, 'origOutageEndFormat', outage.outageEndDate);
        setStartAndEndDateForFeature(feature, outage.outageStartDate, outage.outageEndDate);
    }

    if (outage.isAvailable) {
        if (hasAnUpcomingOutage(outage, currentDate)) {
            notice = setUpUpcomingOutageNotfication(feature, translator);
        }
    } else if (outage.reason === OUTAGE_TYPES.UNAVAILABLE) {
        notice = setUpUnavailableNotification(feature, translator);
    } else if (outage.reason === OUTAGE_TYPES.OUTAGE) {
        notice = setUpPlannedOutageNotification(feature, translator);
    }

    return notice;
}

function setPaymentOptionsDate(notice1, notice2) {
    const dateOneStart = formatToJsDate(notice1.origOutageStartFormat);
    const dateOneEnd = formatToJsDate(notice1.origOutageEndFormat);
    const dateTwoStart = formatToJsDate(notice2.origOutageStartFormat);
    const dateTwoEnd = formatToJsDate(notice2.origOutageEndFormat);

    // Find the shortest distance between an outage start time and outage end time
    if (dateOneStart > dateTwoStart) {
        paymentOutageResult.origOutageStartFormat = notice1.origOutageStartFormat;
        paymentOutageResult.outageStart = formatDate(dateOneStart);
    } else if (dateOneStart <= dateTwoStart) {
        paymentOutageResult.origOutageStartFormat = notice2.origOutageStartFormat;
        paymentOutageResult.outageStart = formatDate(dateTwoStart);
    }

    if (dateOneEnd < dateTwoEnd) {
        paymentOutageResult.origOutageStartFormat = notice1.origOutageEndFormat;
        paymentOutageResult.outageEnd = formatDate(dateOneEnd);
    } else if (dateOneEnd >= dateTwoEnd) {
        paymentOutageResult.origOutageStartFormat = notice2.origOutageEndFormat;
        paymentOutageResult.outageEnd = formatDate(dateTwoEnd);
    }
}

function setPaymentOutageResult(notice1, notice2, translator) {
    let notice;

    if (isBothUnavailable(notice1, notice2)) {
        notice = setUpUnavailableNotification(paymentOutageResult, translator);
    } else if (isBothOutage(notice1, notice2)) {
        setPaymentOptionsDate(notice1, notice2);
        notice = setUpPlannedOutageNotification(paymentOutageResult, translator);
    }

    return notice;
}

function getFeatureFlagsTool(authData) {
    return WMICSplitIoFeatureFlags.getWMICSplitIoFeatureFlags(authData);
}

function useFlags(authData) {
    return getFeatureFlagsTool(authData).wmicUseFlags()
}

function queryAvailabilityAMP(ldFlags, key) {
    const currentDate = new Date();
    let availabilityResponse = ldFlags[key];
    if (!availabilityResponse) {
        return { isAvailable: false };
    }
    const hasUpcomingOutage = hasOutageDateRange(availabilityResponse)
        && formatToJsDate(availabilityResponse.outageStartDate) >= currentDate;
    const isCurrentOutage = hasOutageDateRange(availabilityResponse)
        && formatToJsDate(availabilityResponse.outageStartDate) < currentDate
        && formatToJsDate(availabilityResponse.outageEndDate) >= currentDate;

    if (typeof availabilityResponse === 'boolean') {
        availabilityResponse = { isAvailable: availabilityResponse };
    }

    if (hasUpcomingOutage) {
        availabilityResponse.reason = OUTAGE_TYPES.OUTAGE;
    } else if (isCurrentOutage) {
        availabilityResponse.reason = OUTAGE_TYPES.UNAVAILABLE;
        availabilityResponse.isAvailable = false;
    } else if (availabilityResponse.isAvailable === false) {
        availabilityResponse.reason = OUTAGE_TYPES.UNAVAILABLE;
    } else {
        availabilityResponse.isAvailable = true;
        availabilityResponse.reason = OUTAGE_TYPES.AVAILABLE;
    }
    return availabilityResponse;
}

export default {
    queryAvailabilityQB,
    queryAvailabilityAMP,
    getFeatureFlagsTool,
    useFlags,
    getClaimsAvailabilityInfo: (claimOutage, currentDate, translator) => {
        return setOutageResult(claimOutage, claimOutageResult, currentDate, translator);
    },
    getCardAvailabilityInfo: (cardOutage, currentDate, translator) => {
        return setOutageResult(cardOutage, cardOutageResult, currentDate, translator);
    },
    getBankAvailabilityInfo: (bankOutage, currentDate, translator) => {
        return setOutageResult(bankOutage, bankOutageResult, currentDate, translator);
    },
    getPolicyChangeAvailabilityInfo: (policyChangeOutage, currentDate, translator) => {
        return setOutageResult(policyChangeOutage, policyChangeOutageResult, currentDate, translator);
    },
    getPaymentsAvailabilityInfo: (paymentsOutage, currentDate, translator) => {
        return setOutageResult(paymentsOutage, paymentsOutageResult, currentDate, translator);
    },
    getAMPAvailabilityInfo: (AMPOutage, currentDate, translator) => {
        return setOutageResult(AMPOutage, AMPOutageResult, currentDate, translator);
    },
    getManageBankAccountsAvailabilityInfo: (manageBankAccountsOutage, currentDate, translator) => {
        return setOutageResult(manageBankAccountsOutage, manageBankAccountsOutageResult, currentDate, translator);
    },
    setBothPaymentsDownNotice: (paymentNoticeList, translator) => {
        let paymentNotice = [];
        if (isSameAvailability(paymentNoticeList[0], paymentNoticeList[1])) {
            paymentNotice.push(setPaymentOutageResult(paymentNoticeList[0], paymentNoticeList[1], translator));
        } else {
            paymentNotice = paymentNoticeList;
        }
        return paymentNotice;
    },
    getNoticeTypes: () => {
        return NOTICE_TYPE;
    },
    getOutageTypes: () => {
        return OUTAGE_TYPES;
    },
    getNames: () => {
        return FEATURE_NAMES;
    },
    getFeatureFlags: () => {
        return FEATURE_FLAG_NAMES;
    },
    getFeatureFlagsDefaultValues: () => {
        return FEATURE_FLAG_DEFAULT_VALUES;
    }
};