/* eslint-disable no-secrets/no-secrets */
import React, {
    useCallback,
    useContext,
    useState,
    useEffect,
    useMemo,
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICConstantsUtil, WMICLogger, MODAL_CONSTANTS, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RenewalWarning } from 'wmic-pe-capability-gateway-policychange-common-react';
import { WMICPolicyChangeUtil } from 'wmic-pe-capability-gateway-common-react';

import commonMessages from '../../PolicyChangeCommon.messages';
import metadata from './WMICTransactionDetailsPage.metadata.json5';
import messages from './WMICTransactionDetailsPage.messages';

function WMICTransactionDetailsPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping
    } = props;

    const { EndorsementService } = useDependencies('EndorsementService');
    const [showErrors, setShowErrors] = useState(false);
    const [showRenewalWarning, setShowRenewalWarning] = useState(undefined);
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { setWizardLoading, showWarning } = useWizardModals();
    const rateAsOfDate = _.get(policyChangeVM, 'baseData.rateAsOfDate.value');
    const policyNumber = _.get(policyChangeVM, 'policyNumber.value');
    const termNumber = _.get(policyChangeVM, 'termNumber.value');
    const [isInitialized, setInitialized] = useState(WMICConstantsUtil.alreadyInitialized(rateAsOfDate));

    const translator = useContext(TranslatorContext);
    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);

    const { isComponentValid, onValidate } = useValidation('WMICTransactionDetailsPage');

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        const initializeConstants = async () => {
            try {
                const constantsInitialized = await WMICConstantsUtil.initialize(rateAsOfDate, authHeader);

                setInitialized(constantsInitialized);
            } catch (error) {
                WMICLogger.error('Constants util initialization failed', error);
            }
        };

        const checkUpcomingRenewal = async () => {
            const upcomingRenewal = await WMICPolicyChangeUtil.hasUpcomingRenewal(
                policyNumber,
                termNumber,
                authHeader
            ).catch((error) => {
                WMICLogger.error('CheckUpcomingRenewal failed', error);
            });

            setShowRenewalWarning(upcomingRenewal);
        };

        checkUpcomingRenewal();
        if (!isInitialized) {
            initializeConstants();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        try {

            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));
            const effDate = _.get(policyChangeVM, 'baseData.effectiveDate.value');
            const description = _.get(policyChangeVM, 'description.value');
            const response = await EndorsementService.checkEffectiveDateIsValid([policyNumber, termNumber, effDate], authHeader);

            if (typeof response === 'string' && response.length > 0) {
                return false;
            }

            const loadResponse = await EndorsementService.loadEndorsementWithEffectiveDateAndDescription(
                [policyNumber, effDate, description],
                authHeader
            );

            _.extend(policyChangeVM.value, loadResponse);

            const saveResponse = await EndorsementService.saveWithNoValidationRuleCheck(
                [policyChangeVM.value],
                authHeader
            );

            _.extend(policyChangeVM.value, saveResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM;
        } finally {
            setShowErrors(true);
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EndorsementService, authHeader, policyChangeVM, policyNumber, setWizardLoading, translator, termNumber, isComponentValid]);

    const showWarningModal = () => {
        showWarning({
            title: messages.alerts,
            message: translator(messages.changeEffectiveDateWarning),
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.ERROR,
            confirmButtonText: commonMessages.ok,
        });
    };

    const checkInvalidationFlag = (value, path) => {
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }

        _.set(policyChangeVM, path, value);
        updateWizardData(policyChangeVM);

        showWarningModal();
    };

    const overrideProps = {
        '@field': {
            parentNode: policyChangeVM
        },
        renewalWarningMessage: {
            visible: showRenewalWarning,
        },
        effectiveDate: {
            maxDate: _.get(policyChangeVM, 'baseData.periodEndDate.value'),
            minDate: _.get(policyChangeVM, 'baseData.minimumEffectiveDate.value'),
            readOnly: !canEditPolicyChange
        },
        description: {
            readOnly: !canEditPolicyChange,
            onValueChange: (newValue, path) => {
                // TODO: Transaction Details Description - Revisit to explore if this can be removed.
                // An onValueChange is required here due to MasterInput incorrectly setting
                // the description VM to a string value instead of updating the .value property
                // -- Comment out this onValueChange prop to get original behaviour --
                 _.set(policyChangeVM, `${path}.value`, newValue);
                 updateWizardData(policyChangeVM);
            },
        }
    };

    const resolvers = {
        resolveComponentMap: {
            RenewalWarning
        },
        resolveCallbackMap: {
            checkInvalidationFlag
        }
    };

    if (!isInitialized) {
        return null;
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            showPrevious={false}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={isSkipping}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                showErrors={showErrors}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICTransactionDetailsPage.propTypes = {
    policyChangeVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired
};

export default withViewModelService(WMICTransactionDetailsPage);
