/* eslint-disable indent */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import { DateField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName } from '../WMICRemoveVehicleWizardUtils';
import { MAX_DAYS_IN_PAST, STATE_KEYS, PERMITTED_FUTURE_POLICY_CHANGES } from '../WMICRemoveVehicleWizardConsts';

export const checkEffectiveDate = (modelState, policy, policyTransactions) => {
    const isEffectiveDateValidCheck = !_.isEmpty(modelState[STATE_KEYS.EFFECTIVE_DATE]);
    if (isEffectiveDateValidCheck) {
        const effectiveDate = moment(modelState[STATE_KEYS.EFFECTIVE_DATE]).startOf('day');
        let diffDays = 0;
        let isInPolicyRange = true;
        diffDays = moment().startOf('day').diff(effectiveDate, 'days');
        isInPolicyRange = effectiveDate.isBetween(
            moment(policy.currentPeriod.effective).startOf('day'),
            moment(policy.currentPeriod.expiration).endOf('day'),
            undefined,
            '[)'
        );

        const _policyTransactions = policyTransactions.policyTransactions.filter((pt) => !PERMITTED_FUTURE_POLICY_CHANGES.includes(pt.type));
        const hasFutureTransaction = _.some(_policyTransactions, (pt) =>
            moment(pt.completionDate).startOf('day').isAfter(effectiveDate) || moment(pt.completionDate).startOf('day').isSame(effectiveDate));

        const checkResult = ({
            isDiffInRange: diffDays < MAX_DAYS_IN_PAST,
            diffDays: diffDays,
            isInPolicyRange: isInPolicyRange,
            hasFutureTransaction: hasFutureTransaction
        });

        checkResult.summary = (
            !checkResult.isDiffInRange || checkResult.diffDays < 0
            || !checkResult.isInPolicyRange || hasFutureTransaction);

        return checkResult;
    }

    return false;
};

const WMICRemoveVehicleEffectiveDateStep = (props) => {
    const { modelState, modelStateDispatch, ...otherProps } = props;
    const translator = useTranslator();
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    const focusRemoveVehicleEffectiveDateLabel = () => {
        const headerTag = document.getElementById('WMICRemoveVehicleEffectiveDateLabel');
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: false});
        }
    };

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleEffectiveDateLabel();
        }
    });

    const getWholeDateRelativeToToday = (dayOffset) => {        
        const today = new Date();
        today.setHours(0,0,0,0);
        today.setDate(today.getDate() + dayOffset);
        return today;
    }

    return (
        <div {...otherProps}>
            <p className="mb-5">
                <div className={styles['wmic-field-label-fit-to-content']} id="WMICRemoveVehicleEffectiveDateLabel">
                    {WMICRichTextUtil.translateRichText(translator(messages.effectiveDateMessage, { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) }))}
                </div>
            </p>
            <div className="ww-edit-field-container">
                <DateField
                    id="remove_vehicle_date_combobox"
                    label={translator(messages.effectiveDateLabel)}
                    className={styles['wmic-field-fit-to-content']}
                    labelClassName={styles['wmic-field-label-fit-to-content']}
                    maxDate={getWholeDateRelativeToToday(30).toISOString()}
                    minDate={getWholeDateRelativeToToday(-60).toISOString()}
                    onValueChange={(v) => modelStateDispatch({ type: STATE_KEYS.EFFECTIVE_DATE, value: v })}
                    value={modelState[STATE_KEYS.EFFECTIVE_DATE]}
                />
            </div>
        </div>
    );
}

WMICRemoveVehicleEffectiveDateStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleEffectiveDateStep;
