import { defineMessages } from 'react-intl';

export default defineMessages({
    previewPayments: {
        id: 'quoteandbind.views.payment-details.PreviewPayments',
        defaultMessage: 'Preview Payments'
    },
    dueDate: {
        id: 'quoteandbind.views.payment-details.DueDate',
        defaultMessage: 'Due Date'
    },
    category: {
        id: 'quoteandbind.views.payment-details.Category',
        defaultMessage: 'Category'
    },
    payment: {
        id: 'quoteandbind.views.payment-details.Payment',
        defaultMessage: 'Payment'
    },
    amount: {
        id: 'quoteandbind.views.payment-details.Amount',
        defaultMessage: 'Amount'
    },
});