import React, { useContext } from 'react';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { BurgerMenuLink } from '@jutro/router';
import messages from './WMICTrainingMaterialLink.messages'
import styles from './WMICTrainingMaterialLink.module.scss'

const WMICTrainingMaterialLink = ({ isMobile = false }) => {
    const { serviceUrls } = appConfig;
    const translator = useContext(TranslatorContext)
    const linkStyle = isMobile ? styles.trainingMaterialBurgerMenuLink : styles.subHeaderCustomComponent

    const renderLink = () => <a className={linkStyle} 
                             href={serviceUrls.trainingmaterial}
                             target="_blank" 
                             rel="noreferrer"
                             type="link"
                             role="menuitem">
                                {translator(messages.wmicTrainingMaterialLink)}
                             </a>
    

    return isMobile ?
           <BurgerMenuLink id="trainingMaterialMenuItem" key="trainingMaterialMenuItem" isContainerOnly>
                {renderLink()}
           </BurgerMenuLink>
           :
           <div className={styles.subHeaderCustomComponentParent}>
                {renderLink()}
           </div>
}

export default WMICTrainingMaterialLink;