import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Accordion } from '@jutro/legacy/components';
import { QuoteClauseTable, WMICCard, WMICAccordionCard, WMICHeading, ClausesComponentVM } from 'wmic-pe-components-platform-react';
import { Position } from "wmic-pe-portals-utils-js";
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import WMICGLCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-gl-react/util/WMICGLCoverageUtil";

import messages from './WMICGLPropertyLineCoverages.messages';
import metadata from './WMICGLPropertyLineCoverages.metadata.json5';

function WMICGLPropertyLineCoverages(props) {
    const {
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        onValidate,
        quoteID,
        jobVM,
        isBoundPolicy,
        readOnly
    } = props;

    const lobDataPath = isBoundPolicy ? 'lobs' : 'lobData';
    const coveragePath = `${lobDataPath}.generalLiability`;
    const { isAccordionValid } = useAccordionValidation(onValidate);

    const lineCoverages = WMICGLCoverageUtil.getPropertyLineCoverages(
        _.get(jobVM, coveragePath)
    );

    const generateClauseData = (coverageColumnData) => {
        return coverageColumnData.map(({ lob, code }) => {
            return {
                code: code,
                path: `${lob.path}.coverages.propertyLineCoverages`,
                clauses: lineCoverages
            };
        });
    }

    const getLineCoverages = () => {
        // only show these categories
        const categoryMap = ['extraExpense', 'propertyOffPremise'];

        const categorizedCoverages = WMICGLCoverageUtil.categorizePropertyLineCoverages(lineCoverages);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);

            if (lineCoverage.coverages.length === 0) {
                return (<></>)
            }

            if (!isBoundPolicy) {
                const coverageColumnData = WMICGLCoverageUtil.generateColumnData(jobVM);
                const coverageTableData = [{
                    data: lineCoverage.coverages,
                    tableContent: generateClauseData(coverageColumnData)
                }]

                return (<>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={lineCoverage.categoryName} />
                    <QuoteClauseTable
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        onValidate={onValidate}
                    />
                </>)
            }

            return (
                <>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={lineCoverage.categoryName}
                    />
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={lineCoverage.coverages}
                    />
                </>
            );
        });

        return coverageAccordions;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: jobVM
        },
        propertyLineCoveragesAccordions: {
            content: getLineCoverages()
        }
    }

    const resolvers = {
        resolveComponentMap: {
            Accordion
        }
    }

    return (
        <WMICCard className="gw-mb-4">
            <Accordion id="policyLevelAccordian"
                showFrame={false}
                closeOthers={false}
            >
                <WMICAccordionCard
                    id="policyLevelAccordianCard"
                    title={messages.policyLevelCoverages}
                    isValid={isAccordionValid('policyLevelAccordianCard')}
                    chevron
                    chevronAlignment='left'
                    headerVariant='h2'
                    headerSize='h2'
                    className='gw-mb-0'
                    cardBodyClassName='gw-mx-2'
                >
                    <ViewModelForm
                        model={jobVM}
                        uiProps={metadata.componentContent}
                        overrideProps={overrideProps}
                        componentMap={resolvers.resolveComponentMap}
                    />
                </WMICAccordionCard>
            </Accordion>
        </WMICCard>
    );
}

export default WMICGLPropertyLineCoverages;
