// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPolicyDetailsComponent__policyDetailsPage .app__WMICPolicyDetailsComponent__mainTitle{border-bottom:1px solid var(--GW-BORDER-COLOR-1)}.app__WMICPolicyDetailsComponent__policyDetailsPage .app__WMICPolicyDetailsComponent__pupContainer{margin-bottom:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-gateway-common-react/components/WMICPolicyDetailsComponent/WMICPolicyDetailsComponent.module.scss"],"names":[],"mappings":"AAGE,gGACE,gDAAA,CAGF,mGACE,gCAAA","sourcesContent":["@import \"~producer-engage/public/styles/wmicPEDefaultTheme/sass/theme/colors/pre-set\";\n\n.policyDetailsPage {\n  .mainTitle {\n    border-bottom: 1px solid var(--GW-BORDER-COLOR-1);\n  }\n\n  .pupContainer {\n    margin-bottom: var(--GW-LAYOUT-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policyDetailsPage": "app__WMICPolicyDetailsComponent__policyDetailsPage",
	"mainTitle": "app__WMICPolicyDetailsComponent__mainTitle",
	"pupContainer": "app__WMICPolicyDetailsComponent__pupContainer"
};
export default ___CSS_LOADER_EXPORT___;
