import _ from 'lodash';
import { EntityUtil } from '@xengage/gw-portals-util-js';
import {
    LINE_OF_BUSINESS,
    CONSTANTS,
    AddressType,
} from 'wmic-pe-portals-utils-js';

const createHouseholdMember = (jobVM, namedInsured) => {
    const oldPNI = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.value');
    const isPersonPNI = namedInsured?.relationshipToPrimaryInsured_WMIC === CONSTANTS.PRIMARY_INSURED_RELATIONSHIP.INSURED;
    let namedInsuredTemp = {};

    if (!namedInsured) {
        const today = new Date();

        namedInsuredTemp = {
            tempID: EntityUtil.nextId(),
            minDOB: {
                year: today.getFullYear() - CONSTANTS.BOT_PARAM_CONFIG.MAXIMUM_LEGAL_AGE,
                month: today.getMonth(),
                day: today.getDate(),
            },
            maxDOB: {
                year: today.getFullYear() - CONSTANTS.BOT_PARAM_CONFIG.MINIMUM_LEGAL_AGE,
                month: today.getMonth(),
                day: today.getDate()
            }
        }
    }

    const householdMember = {
        tempID: EntityUtil.nextId(),
        contactSameAsAccount: true,
        addressSameAsAccount: true,
        isPNI: false,
        memberIncidents: [],
        householdOperator: {
            publicId: null,
            memberExcluded: null,
            licensedToDrive: null,
            licenseNumber: null,
            licenseState: null
        },
        hasLegalHistory: null,
        person: namedInsured ? { ...namedInsured} : { ...namedInsuredTemp },
        locationAddress: {
            ..._.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {}),
            addressType: 'home',
            publicID: undefined
        },
        relationshipToPrimaryInsured_WMIC: namedInsured?.relationshipToPrimaryInsured_WMIC,
        estateOf: namedInsured?.estateOf_WMIC
    }

    if (isPersonPNI) {
        householdMember.primaryPhoneType = oldPNI.primaryPhoneType ?? jobVM.baseData.primaryNamedInsured_WMIC.primaryPhoneType.value;
        householdMember.homeNumber = oldPNI.homeNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.homeNumber.value;
        householdMember.workNumber = oldPNI.workNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.workNumber.value;
        householdMember.cellNumber = oldPNI.cellNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.cellNumber.value;
    }

    if (namedInsured && namedInsured.contactPublicID) {
        _.set(householdMember, 'person.publicID', namedInsured.contactPublicID);
    }

    return householdMember;
}

const clearOldPNI = (jobVM, viewModelService) => {
    const unknownRelationship = viewModelService.productMetadata.get('pc').types.getTypelist('ApplicantRelation_wmic').getCode(CONSTANTS.UNKNOWN);
    const existingPniPublicID = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.publicID.value');
    const allHouseholdMembers = _.get(jobVM, 'lobData.personalUmbrella.coverables.householdMembers.value', []);

    const pniHouseholdMember = allHouseholdMembers.find(
        (householdMember) => householdMember.person.publicID === existingPniPublicID
    )
    if (pniHouseholdMember) {
        _.set(pniHouseholdMember, 'isPNI', false);
        _.set(pniHouseholdMember, 'relationshipToPrimaryInsured_WMIC', unknownRelationship.code)
    }
}

const isHouseholdMemberPNI = (householdMember, jobVM) => {
    const memberPublicID = _.get(householdMember, 'person.publicID.value');
    const pniPublicID = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.publicID.value');

    return memberPublicID === pniPublicID;
}

export default {
    createHouseholdMember,
    isHouseholdMemberPNI,
    clearOldPNI
}
