import { CONSTANTS } from 'wmic-pe-portals-utils-js';

const userRoles = {
    read_write: 'GPA_Broker_Write',
    bmsi_write: 'BMSI_Broker_Write',
    read_broker: 'GPA_Broker_ReadOnly',
    read_wawanesa: 'Read Only (Canada)'
};

function searchRoles(roles, ...hasRole) {
    let found = false;

    const checkRole = (nameOfRole) => {
        let passed = false;

        for (let i = 0, j = hasRole.length - 1; !passed && i <= (j - i); i++) {
            passed = hasRole[i](nameOfRole) || hasRole[j - i](nameOfRole);
        }

        return passed;
    };

    if (roles) {
        for (let i = 0, j = roles.length - 1; !found && i <= (j - i); i++) {
            found = checkRole(roles[i].name) || checkRole(roles[j - i].name);
        }
    }

    return found;
}

function getFromSettings(key, defaultValue) {
    if (userRoles) {
        const valueFound = userRoles[key];
        if (typeof valueFound !== 'undefined') {
            return valueFound;
        }
    }

    return defaultValue;
}

function getReadWriteRoleName() {
    const key = 'read_write';
    const defaultValue = 'GPA_Broker_Write';
    return getFromSettings(key, defaultValue);
}

function getReadWriteBMSIRoleName() {
    const key = 'bmsi_write';
    const defaultValue = 'BMSI_Broker_Write';
    return getFromSettings(key, defaultValue);
}

function getReadWawanesaRoleName() {
    const key = 'read_wawanesa';
    const defaultValue = 'Read Only (Canada)';
    return getFromSettings(key, defaultValue);
}


function isRead(name) {
    return name === getReadWawanesaRoleName();
}

function isReadWrite(name) {
    return name === getReadWriteRoleName();
}

function isBMSIReadWrite(name) {
    return name === getReadWriteBMSIRoleName();
}

const isUnderwriterAssistant = name => name === CONSTANTS.INTERNAL_UNDERWRITING_ROLES.UNDERWRITING_ASSISTANT_CANADA;

const isUnderwriter = name => name === CONSTANTS.INTERNAL_UNDERWRITING_ROLES.UNDERWRITER_CANADA;

const isManagementUnderwriter = name => name === CONSTANTS.INTERNAL_UNDERWRITING_ROLES.UNDERWRITING_MGMT_CANADA;

const isExecutiveUnderwriter = name => name === CONSTANTS.INTERNAL_UNDERWRITING_ROLES.EXECUTIVE_UNDERWRITING_CANADA;

export default {
    canCreateAccount: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canEditAccount: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canCreateSubmission: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canEditPolicyChange: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canEditPolicyRenewal: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canEditSubmission: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canBindPolicy: (roles) => searchRoles(roles, isReadWrite),
    canQuote: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canViewSubmission: (roles) => searchRoles(roles, isRead, isReadWrite, isUnderwriter, isUnderwriterAssistant, isManagementUnderwriter, isExecutiveUnderwriter),
    canWithdrawSubmission: (roles) => searchRoles(roles, isReadWrite),
    hasGPABrokerWriteRole: (roles) => searchRoles(roles, isReadWrite),
    hasBMSIBrokerWriteRole: (roles) => searchRoles(roles, isBMSIReadWrite),
    hasInternalUnderwriterRole: (roles) => searchRoles(roles, isUnderwriter, isUnderwriterAssistant, isManagementUnderwriter, isExecutiveUnderwriter),
    hasGPAReadWawanesaRole: (roles) => searchRoles(roles, isRead),
    canUploadDocument: (roles) => searchRoles(roles, isReadWrite, isUnderwriter, isManagementUnderwriter, isExecutiveUnderwriter),
    canEditPayment: (roles) => searchRoles(roles, isReadWrite)
};
