/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName } from '../WMICRemoveVehicleWizardUtils';
import { STEPS, PRIMARY_USE_TEXT, STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';
import { Flex, FlexItem } from "@jutro/layout";

const WMICRemoveVehicleUsingReviewStep = (props) => {
    const { modelState, viewStateDispatch, ...otherProps } = props;
    const translator = useTranslator();
    const handleEditVehicle = useCallback((vehicle) => {
        viewStateDispatch({value:  {step: STEPS.VEHICLE_USING, currentVehicleNumber: vehicle.vehicleNumber}});
    }, [viewStateDispatch]);
    const intl = useContext(IntlContext);

    const formattedNumber = (amount) => intl.formatNumber(amount);
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
        }
    });

    return (
        <div {...otherProps}>
            <p className='mb-5' >                
                <div className={styles['wmic-field-label-fit-to-content']} >
                    { translator(messages.selectVehicleMessage) }
                </div>
            </p>
            <Flex gap='none' className={styles.wmicFlexContainer} role='list'>
                {
                    _.map(modelState[STATE_KEYS.REMAINING_VEHICLES], (vehicle, idx) => (
                        <div className={styles.wmicFlexColumn} role='listitem'>
                            <FlexItem grow={1} className={styles.wmicBox}>
                                <div className={styles.wmicBoxContent} key={idx}>
                                    <h3><span className={styles.wmicStrong}>{getVehicleName(vehicle)}</span></h3>
                                    <div className='pb-4'>
                                        <p>
                                            <span className={styles.wmicStrong}>{translator(messages.use)}</span> {PRIMARY_USE_TEXT[vehicle.primaryUse]}
                                        </p>
                                        <p>
                                            <span className={styles.wmicStrong}>{translator(messages.annualMileage)}</span> {formattedNumber(vehicle.annualMileage)}
                                        </p>
                                    </div>
                                    <div>
                                        <WMICButton type="primary" 
                                            id={`edit_vehicle${vehicle.vehicleNumber}_button`}
                                            aria-label={translator(messages.editVehicleButtonAriaMessage, { vehicleName: getVehicleName(vehicle), vehicleUseage: vehicle.primaryUse, annualMileage: vehicle.annualMileage})}
                                            onClick={() => handleEditVehicle(vehicle)}>
                                            {translator(messages.editVehicle)}
                                        </WMICButton>
                                    </div>
                                </div>
                            </FlexItem>
                        </div>
                        )
                    )
                }
            </Flex>
        </div>
    );
}

WMICRemoveVehicleUsingReviewStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    viewStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleUsingReviewStep;
