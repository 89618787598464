// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__WMICPreviewPaymentsModal__previewPaymentModal div[class*=hasVerticalScrollbar]{box-shadow:none}.app__WMICPreviewPaymentsModal__previewPaymentModal div[class*=Cell]:focus{box-shadow:none!important}.app__WMICPreviewPaymentsModal__previewPaymentModal div[class=rt-resizer]{display:none}.app__WMICPreviewPaymentsModal__previewPaymentModal div[id=previewPaymentsTable] div[class*=expandableRow]{padding-bottom:0;padding-right:0;padding-top:0}.app__WMICPreviewPaymentsModal__previewPaymentModal div[id*=breakdownTable_] div[class*=tableHeader]{display:none}.app__WMICPreviewPaymentsModal__previewPaymentModal div[id*=breakdownTable_] div[class*=tableBody]{border-top:thin dotted}.app__WMICPreviewPaymentsModal__emptyCell span{display:none}", "",{"version":3,"sources":["webpack://./src/customer/capabilities-react/wmic-pe-capability-quoteandbind-common-react/pages/WMICPaymentPage/WMICPaymentPlansList/WMICPreviewPaymentsModal/WMICPreviewPaymentsModal.module.scss"],"names":[],"mappings":"AACI,qFACI,eAAA,CAGJ,2EACI,yBAAA,CAGJ,0EACI,YAAA,CAIA,2GAGI,gBAAA,CADA,eAAA,CADA,aAEA,CAKJ,qGACI,YAAA,CAGJ,mGACI,sBAAA,CAMR,+CACI,YAAA","sourcesContent":[".previewPaymentModal {\n    div[class*=\"hasVerticalScrollbar\"] {\n        box-shadow: none;\n    }\n\n    div[class*=\"Cell\"]:focus {\n        box-shadow: none !important;\n    }\n\n    div[class=\"rt-resizer\"] {\n        display: none;\n    }\n\n    div[id=\"previewPaymentsTable\"] {\n        div[class*=\"expandableRow\"] {\n            padding-top: 0px;\n            padding-right: 0px;\n            padding-bottom: 0px;\n        }\n    }\n\n    div[id*=\"breakdownTable_\"] {\n        div[class*=\"tableHeader\"] {\n            display: none;\n        }\n\n        div[class*=\"tableBody\"] {\n            border-top: dotted thin;\n        }\n    }\n}\n\n.emptyCell {\n    span {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewPaymentModal": "app__WMICPreviewPaymentsModal__previewPaymentModal",
	"emptyCell": "app__WMICPreviewPaymentsModal__emptyCell"
};
export default ___CSS_LOADER_EXPORT___;
