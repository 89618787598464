/* eslint-disable react/prop-types */
import React, {
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext
} from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/legacy/datatable';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import messages from './WMICPreviewPaymentsModal.messages';
import styles from './WMICPreviewPaymentsModal.module.scss';

const WMICPreviewPaymentsModal = ({
    isOpen,
    onResolve,
    payments
}) => {
    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);

    const handleClose = () => {
        onResolve();
    };

    const getDueDateCell = (row, rowIndex) => {
        const dueDate = row.dueDate;
        const dueDateObj = new Date(dueDate.year, dueDate.month, dueDate.day);
        return intl.formatDate(dueDateObj, { year: 'numeric', month: 'numeric', day: 'numeric' })
    };

    const getAmountCell = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`totalpayment_${rowIndex}`}
                value={row.amount}
                dataType="object"
                showFractions
                readOnly
                hideLabel
            />
        );
    };

    const renderBreakdown = ({ breakdowns, dueDate }) => {
        const key = `${dueDate.year}-${dueDate.month}-${dueDate.day}`;
        return (
            <DataTable
                id={`breakdownTable_${key}`}
                data={breakdowns}
                showPagination={false}
                showSearch={false}>
                <DisplayColumn
                    id={`breakdownDueDate_${key}`}
                    cellClassName={styles.emptyCell}
                    sortable={false} />
                <DisplayColumn
                    id={`breakdownPayment_${key}`}
                    textAlign="left"
                    path="type"
                    sortable={false} />
                <DisplayColumn
                    id={`breakdownCategory_${key}`}
                    textAlign="left"
                    path="chargeCategory"
                    sortable={false} />
                <DisplayColumn
                    id={`breakdownAmount_${key}`}
                    textAlign="right"
                    renderCell={getAmountCell}
                    sortable={false} />
            </DataTable>
        );
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
            className={styles.previewPaymentModal}>
            <ModalHeader title={translator(messages.previewPayments)} />
            <ModalBody>
                <DataTable
                    id="previewPaymentsTable"
                    data={payments}
                    showPagination={false}
                    showSearch={false}
                    expandable
                    multipleRowsExpandable
                    headerMultiline
                    renderExpanderContent={renderBreakdown}>
                    <DisplayColumn
                        id="dueDate"
                        textAlign="left"
                        header={translator(messages.dueDate)}
                        renderCell={getDueDateCell}
                        sortable={false} />
                    <DisplayColumn
                        id="payment"
                        header={translator(messages.payment)}
                        cellClassName={styles.emptyCell}
                        sortable={false} />
                    <DisplayColumn
                        id="category"
                        header={translator(messages.category)}
                        cellClassName={styles.emptyCell}
                        sortable={false} />
                    <DisplayColumn
                        id="amount"
                        textAlign="right"
                        header={translator(messages.amount)}
                        renderCell={getAmountCell}
                        sortable={false} />
                </DataTable>
            </ModalBody>
            <ModalFooter>
                <div>
                    <WMICButton
                        onClick={handleClose}
                        type="primary"
                    >
                        {translator(commonMessages.close)}
                    </WMICButton>
                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICPreviewPaymentsModal.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WMICPreviewPaymentsModal;