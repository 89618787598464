/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    roofTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Roof",
        defaultMessage: "Roof"
    },
    atLeastOneRoof: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.roof.Adding At Least One",
        defaultMessage: "&lt;span&gt;Adding at least &lt;b&gt;one Roof Type&lt;/b&gt; may produce a better rating for the customer&lt;/span&gt;"
    },
    atLeastOneRoofRequired: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.roof.Adding At Least One Required",
        defaultMessage: "&lt;span&gt;At least &lt;b&gt;one Roof Type&lt;/b&gt; is required&lt;/span&gt;"
    },
    atLeastOnePlumbing: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.plumbing.Adding At Least One",
        defaultMessage: "&lt;span&gt;At least &lt;b&gt;one Plumbing Type&lt;/b&gt; is required&lt;/span&gt;"
    },
    addRoofTypeBtn: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Add Type",
        defaultMessage: "Add Roof Type"
    },
    installationCertificate: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Certificate Received",
        defaultMessage: "Installation Certificate Received?"
    },
    superiorRoofDiscount: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Superior Discount",
        defaultMessage: "Superior Roof Discount?"
    },
    roofUpgradeType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Roof Upgrades",
        defaultMessage: "Roof Upgrades"
    },
    roofUpgradeYear: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Year",
        defaultMessage: "Year"
    },
    electricalInfoRequiredToBind: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionRoofElectricalPlumbing.Electrical.Required To Bind",
        defaultMessage: "This is required to bind the policy"
    },
    electricalTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Electrical",
        defaultMessage: "Electrical"
    },
    electricalWiring: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Wiring",
        defaultMessage: "Wiring"
    },
    electricalWiringDescribeOther: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Describe Other",
        defaultMessage: "Describe Other"
    },
    electricalPanelType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Panel Type",
        defaultMessage: "Panel Type"
    },
    electricalPanelTypeDescribeOther: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.PanelType.Describe Other",
        defaultMessage: "Describe Other"
    },
    electricalNumberAmps: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Number Amps",
        defaultMessage: "Number of Amps"
    },
    electricalNumberAmpsDescribeOther: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.PanelAmps.Describe Other",
        defaultMessage: "Describe Other"
    },
    electricalInspectionFormReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Inspection Form Received",
        defaultMessage: "Electrical Inspection Form Received?"
    },
    electricalInspectionCompletedDate: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.electricalInspectionCompletedDate",
        defaultMessage: "Electrical Inspection Completed Date"
    },
    electricalUpgradeType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Electrical Upgrades",
        defaultMessage: "Electrical Upgrades"
    },
    electricalUpgradeYear: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Electrical.Year",
        defaultMessage: "Year"
    },
    plumbingTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Plumbing",
        defaultMessage: "Plumbing"
    },
    addPlumbingTypeBtn: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Add Plumbing Type",
        defaultMessage: "Add Plumbing Type"
    },
    plumbingUpgradeType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Plumbing Upgrades",
        defaultMessage: "Plumbing Upgrades"
    },
    plumbingUpgradeYear: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionRoofElectricalPlumbing.Plumbing.Year",
        defaultMessage: "Year"
    }
});
