import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICDateTimeService, WMICValidationUtil, CONSTANTS, WMICUserAccessUtil, WMICLogger } from 'wmic-pe-portals-utils-js';
import {
    WMICHouseholdMemberDetailsComponent,
    WMICHouseholdMemberUtil,
    WMICPUPAddHouseholdMemberDropdownMenu
} from 'wmic-pe-capability-gateway-common-pup-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICPUPPolicyChangeHouseholdMemberPage.metadata.json5';
import messages from './WMICPUPPolicyChangeHouseholdMemberPage.messages';

function WMICPUPPolicyChangeHouseholdMemberPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        viewModelService
    } = props;

    const translator = useContext(TranslatorContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    useDocumentTitle(translator(messages.householdMemberTitle), policyChangeVM);

    // eslint-disable-next-line no-secrets/no-secrets
    const { onValidate, isComponentValid } = useValidation('WMICPUPPolicyChangeHouseholdMemberPage');
    const { authHeader, authUserData } = useAuthentication();
    const { showInfo, showConfirm, setWizardLoading } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddHouseholdMember = (index, namedInsured) => {
        const householdmember = WMICHouseholdMemberUtil.createHouseholdMember(policyChangeVM, namedInsured);
        const newHouseholdMember = viewModelService.create(householdmember,
            'pc',
            // eslint-disable-next-line no-secrets/no-secrets
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.householdmember.HouseholdMemberDTO_WMIC',
            policyChangeVM.aspects.context()
        );

        setShowErrors(false);
        _.set(policyChangeVM, 'isEditingPage.value', true);
        policyChangeVM.lobData.personalUmbrella.coverables.householdMembers.value.push(newHouseholdMember.value);
        updateWizardData(policyChangeVM);

        return newHouseholdMember;
    }

    const onUndoAddHouseholdMember = () => {
        const householdMembers = _.get(policyChangeVM.value, CONSTANTS.HOUSEHOLD_MEMBERS_PATH);

        householdMembers.splice(householdMembers.length - 1, 1);
        _.set(policyChangeVM.value, CONSTANTS.HOUSEHOLD_MEMBERS_PATH, householdMembers);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        updateWizardData(policyChangeVM);
    }

    const onSaveHouseholdMember = async (member) => {
        let newPolicyChangeVM = viewModelService.clone(policyChangeVM);

        if (!WMICHouseholdMemberUtil.isHouseholdMemberPNI(member, newPolicyChangeVM)) {
            WMICHouseholdMemberUtil.clearOldPNI(newPolicyChangeVM, viewModelService);
        }

        try {
            setWizardLoading(true, translator(messages.savingHouseholdMembers));
            newPolicyChangeVM.value = await EndorsementService.saveWithNoValidationRuleCheck(
                [newPolicyChangeVM.value],
                authHeader
            );

            updateWizardData(newPolicyChangeVM);
            setShowErrors(false);

            return true
        } catch (error) {
            WMICLogger.error('Save construction failed', error);
            return false;
        } finally {
            setWizardLoading(false);
        }

        return true;
    };

    const onRemoveHouseholdMember =  (householdMember, index) => {
        if (householdMember?.isPNI?.value) {
            showInfo({
                title: messages.householdMemberTitle,
                message: messages.wmicPUPHouseholdMembersPrimaryNamedInsuredCannotBeRemoved
            });
            return _.noop();
        }

        showConfirm({
            title: messages.householdMemberTitle,
            message: messages.wmicPUPHouseholdMembersRemovingHouseholds
        }).then((response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const allHouseholdMembers = _.get(policyChangeVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, []);

                allHouseholdMembers.splice(index, 1);
                _.set(policyChangeVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, allHouseholdMembers);
                updateWizardData(policyChangeVM);
            }
        });

        return _.noop();
    };

    const onEditHouseholdMember = () => {
        _.set(policyChangeVM, 'isEditingPage.value', true);
        updateWizardData(policyChangeVM);
    }

    const onCancelEditHouseholdMember = () => {
        updateWizardData(policyChangeVM);
    }

    const getDisplayName = (member) => {
        if (!_.isUndefined(member?.person?.displayName)) {
            return member.person.displayName
        }

        return `${member.person.firstName} ${member.person.lastName}`;
    }

    const displayRelationship = (member) => {
        const relationshipTypeList = viewModelService.productMetadata.get('pc').types.getTypelist('ApplicantRelation_wmic');

        if (member?.isPNI) {
            return translator({id: relationshipTypeList.getCode('insured').name});
        }

        if (member?.relationshipToPrimaryInsured_WMIC) {
            return translator({id: relationshipTypeList.getCode(member.relationshipToPrimaryInsured_WMIC).name});
        }

        return '';
    };

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);

        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const onNext = useCallback(async () => {

        if(!isComponentValid)  {
            setShowErrors(true);
            return false;
        }

        if (!isReadOnlyUser) {
            try {
                setWizardLoading(true, translator(commonMessages.savingTransactionDetails));
                const newPolicyChangeVM = viewModelService.clone(policyChangeVM);

                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [newPolicyChangeVM.value],
                    authHeader
                );

                updateWizardData(policyChangeVM);
                return policyChangeVM;
            } finally {
                setWizardLoading(false);
            }
        } else {
            return false
        }
    }, [isComponentValid, isReadOnlyUser, setWizardLoading, translator, viewModelService, policyChangeVM, EndorsementService, authHeader, updateWizardData]);

    const getAddHouseholdMemberButton = ({ isEditing, onClick }) => {
        const additionalNamedInsureds = _.get(policyChangeVM, `${CONSTANTS.ANIS_PATH}.value`);
        const householdMembers = _.get(policyChangeVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, []);

        return <WMICPUPAddHouseholdMemberDropdownMenu
            additionalNamedInsureds={additionalNamedInsureds}
            isEditing={isEditing}
            onClick={onClick}
            householdMembers={householdMembers}>
        </WMICPUPAddHouseholdMemberDropdownMenu>
    };

    const overrideProps = {
        householdMembersListView: {
            detailViewComponent: WMICHouseholdMemberDetailsComponent,
            detailViewComponentProps: {
                jobVM: policyChangeVM
            },
            onValidate,
            value: _.get(policyChangeVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersName),
                    getData: (member) => getDisplayName(member.value)
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersRelationship),
                    getData: (member) => displayRelationship(member.value)
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersDateOfBirth),
                    getData: displayDate,
                    path: 'person.dateOfBirth'
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersPrimaryNamedInsured),
                    path: 'isPNI'
                }
            ],
            toCreate: onAddHouseholdMember,
            addButtonLabel: translator(messages.wmicPUPHouseholdMembersTitle),
            toUndoCreate: onUndoAddHouseholdMember,
            onSave: onSaveHouseholdMember,
            onDelete: onRemoveHouseholdMember,
            toEdit: onEditHouseholdMember,
            onCancel: onCancelEditHouseholdMember,
            showCancelModal: true,
            readOnly: isReadOnlyUser,
            showErrors,
            renderAddButton: getAddHouseholdMemberButton
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHouseholdMemberDetailsComponent
        }
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPUPPolicyChangeHouseholdMemberPage.propTypes = wizardProps;

export default withViewModelService(WMICPUPPolicyChangeHouseholdMemberPage);
